import { FC } from 'react'
import { Steps as LibsSteps } from '@lifedot/atoms/Steps'
import { Step } from '@lifedot/atoms/Steps/Step'

export type StepsProps = {
  currentStep: number
  descriptions: Array<string>
}

export const Steps: FC<StepsProps> = ({ currentStep, descriptions }) => {
  return (
    <LibsSteps current={currentStep} progressDot>
      {descriptions.map((title, index) => (
        <Step stepNumber={index + 1} description={title} key={title} />
      ))}
    </LibsSteps>
  )
}
