import { FC } from 'react'
import { css } from '@emotion/react'
import { Steps } from './Steps'

const styles = {
  wrap: css({
    maxWidth: 640,
    margin: 'auto'
  })
}

type ThreeStepsStyledProps = {
  currentStep: 1 | 2 | 3
}

export const ThreeStepsStyled: FC<ThreeStepsStyledProps> = ({
  currentStep
}) => (
  <div css={styles.wrap}>
    <Steps currentStep={currentStep} descriptions={titles} />
  </div>
)

const titles = [
  'お問い合わせ内容\nの入力',
  '詳細情報\nの入力',
  'ご案内・\nアンケート'
]
