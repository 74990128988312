import { FC, Children, cloneElement, isValidElement, ReactNode } from 'react'
import { css } from '@emotion/react'
import { StepProps } from './Step/Step'

const styles = {
  list: css({
    display: 'flex',
    width: '100%'
  })
}

export interface StepsProps {
  current: number
  children?: ReactNode
  progressDot?: boolean
}

export const Steps: FC<StepsProps> = ({
  current,
  children,
  progressDot = false
}) => {
  const newChildren = Children.map(children, (child) => {
    if (!isValidElement<StepProps>(child)) return child
    return cloneElement(child, {
      isCurrent: current === child.props.stepNumber,
      isFinished: current > child.props.stepNumber,
      progressDot
    })
  })
  return <ol css={styles.list}>{newChildren}</ol>
}
