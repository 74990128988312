import { FC } from 'react'
import { css } from '@emotion/react'
import { Steps } from './Steps'

const styles = {
  wrap: css({
    maxWidth: 800,
    margin: 'auto'
  })
}

type TwoStepsStyledProps = {
  currentStep: 1 | 2
}

export const TwoStepsStyled: FC<TwoStepsStyledProps> = ({ currentStep }) => (
  <div css={styles.wrap}>
    <Steps currentStep={currentStep} descriptions={titles} />
  </div>
)

const titles = ['必要情報の入力', 'お問い合わせ完了']
