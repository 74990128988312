import { FC } from 'react'
import { css } from '@emotion/react'
import CheckIcon from '@material-ui/icons/Check'
import { palette } from '@lifedot/styles/palette'

const styles = {
  step: (colored?: boolean) =>
    css({
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      fontSize: 12,
      position: 'relative',
      flex: 1,
      '&:not(:first-of-type)::before': {
        content: '""',
        height: 2,
        position: 'absolute',
        left: '-50%',
        backgroundColor: colored ? palette.main : palette.gray4,
        width: '100%',
        top: 11
      }
    }),
  dot: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: '50%',
    position: 'relative',
    fontSize: 12,
    zIndex: 1
  }),
  colored: css({
    border: `2px solid ${palette.main}`,
    backgroundColor: palette.main
  }),
  default: (progressDot?: boolean) =>
    css({
      border: `2px solid ${palette.gray4}`,
      backgroundColor: progressDot ? palette.white : palette.gray4,
      color: palette.white
    }),
  description: css({
    display: 'block',
    textAlign: 'center',
    lineHeight: 1.4,
    whiteSpace: 'pre-wrap',
    marginTop: 8
  }),
  bold: css({
    fontWeight: 'bold'
  })
}

export interface StepProps {
  stepNumber: number
  isCurrent?: boolean
  isFinished?: boolean
  description?: string
  progressDot?: boolean
}

export const Step: FC<StepProps> = ({
  stepNumber,
  isCurrent,
  isFinished,
  description,
  progressDot = false
}) => {
  return (
    <li css={[styles.step(isCurrent || isFinished)]}>
      <span
        css={[
          styles.dot,
          isCurrent || isFinished ? styles.colored : styles.default(progressDot)
        ]}
      >
        {isFinished ? (
          <CheckIcon style={{ fontSize: 14 }} />
        ) : (
          !progressDot && stepNumber
        )}
      </span>
      {description && (
        <p css={[styles.description, isCurrent && styles.bold]}>
          {description}
        </p>
      )}
    </li>
  )
}
